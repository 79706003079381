// branding configuration
$publication: 'bristolpost';
$logo-dimensions: 495, 160;
$primary-color: #0572BA;
$header-additional: #4D4D4F;
$brand-hue: 204;
$brand-saturation: 95%;

$brand-color: hsl($brand-hue, $brand-saturation, 37%);
$highlight: hsl($brand-hue, $brand-saturation, 52%);
$hint: hsl($brand-hue, $brand-saturation,  97%);
$lowlight: hsl($brand-hue, $brand-saturation,  12%);
// overrides
@mixin overrides() {
  .social-sites {
    @include social-icons(true);

    .icon {
      @include icons-background($white, $primary-color);
    }

  }
}
